import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";

export default function MobileViewMenuItems({
  closeLeftDrawer,
  openDrawer,
  navigation,
}) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
  };

  const navigateMobileView = (text) => {
    navigation(`/${text}`);
    closeLeftDrawer();
  };

  const list = (anchor) => (
    <Box
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      sx={{ width: "200px" }}
    >
      <List>
        {["Home", "ABOUT", "PRODUCT", "QUALITY", "CONTACT", "Gallery"].map(
          (text) => (
            <div
              key={text}
              style={{
                border: "1px solid black",
                padding: "1%",
                backgroundColor: "#efefef",
              }}
              onClick={() => navigateMobileView(text)}
            >
              <ListItem key={text} >
                <ListItemButton>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </div>
          )
        )}
      </List>
      <Divider />
    </Box>
  );
  return (
    <Drawer
      anchor={"right"}
      open={openDrawer}
      onClose={closeLeftDrawer}
    >
      {list("left")}
    </Drawer>
  );
}
