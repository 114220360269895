import React from "react";
import { TypeAnimation } from "react-type-animation";
import "../App.scss";
import { Link } from "react-router-dom";

export default function WelcomePage() {
  return (
    <div style={{ overflow: "hidden", height: "100vh" }}>
      <div className="try bounding-box" />
      <div class="card-img-overlay card-inverse ">
        <div
          class="card-block pt-2 ml-2 make-center"
          style={{
            display: "block",
            marginTop: "19%",
            marginLeft: "2%",
          }}
        >
          <div
            style={{
              alignContent: "center",
              fontSize: "4.5rem",
              color: "#fff",
              fontWeight: "bolder",
            }}
          >
            We're Here To Help
          </div>
          <div>
            <p
              class="text-white"
              style={{ textDecoration: "none", fontWeight: "500" }}
            >
              <TypeAnimation
                preRenderFirstString={true}
                sequence={[
                  500,
                  "We provides liquid Oxygen",
                  1000,
                  "We provide Mortuary Complex",
                  1000,
                  "We provide Post Mortem Equipment",
                  1000,
                  "We provide various hostpital equipments",
                  500,
                ]}
                speed={50}
                style={{ fontSize: "1.8em" }}
                repeat={Infinity}
              />
            </p>
          </div>
          <Link to="/CONTACT" className="animatedButton">
            Contact US
          </Link>
        </div>
      </div>
    </div>
  );
}
