const { v4: uuidv4 } = require("uuid");
export const turnKeyProjects = [
  {
    id: uuidv4(),
    title: "Modular PM Complex",
    img: require("../../asset/SubProductImages/modular PM complex.jpg"),
    url: "/ModularPMComplex",
  },
  {
    id: uuidv4(),
    title: "Modular ICU / NICU ",
    img: require("../../asset/products/iCUandNICU.jpg"),
    url: "/ModularIcu",
  },
  {
    id: uuidv4(),
    title: "Modular Labour Room ",
    img: require("../../asset/SubProductImages/modularLabourRoom.jpg"),
    url: "/modularLabourRoom",
  },
  {
    id: uuidv4(),
    title: "Modular Operation Theater",
    img: require("../../asset/products/operationTheather.jpg"),
    url: "/modularOperationTheater",
  },
  {
    id: uuidv4(),
    title: "Oxygen Generation Plant (PSA)",
    img: require("../../asset/products/psaOxygenGeneration.jpg"),
    url: "/psa",
  },
  {
    id: uuidv4(),
    title: "Liquid Medical Oxygen Plant (LMO)",
    img: require("../../asset/products/liquidOxygen.jpg"),
    url: "/liquidOxygenPlant",
  },
  {
    id: uuidv4(),
    title: "Medical Gas Pipeline System (MGPS)",
    img: require("../../asset/products/MGPS.jpg"),
    url: "/MGPS",
  },
  {
    id: uuidv4(),
    title: "Hospital Linens Laundry Machines",
    img: require("../../asset/products/laundrySystem.jpg"),
    url: "/laundryMachine",
  },
  {
    id: uuidv4(),
    title: "Effuent/Sewage Water Treatment",
    img: require("../../asset/SewageaWaterBanner.jpg"),
    url: "/waterTreatment",
  },
  {
    id: uuidv4(),
    title: "Central Sterile Supply Department (CSSD)",
    img: require("../../asset/products/CSSD/cssd3.png"),
    url: "/CSSD",
  },
];

export const projects = [
  {
    id: uuidv4(),
    title: "Vileda Hospital Cleaning Solution",
    img: require("../../asset/products/hospitalCleaning.jpg"),
    url: "/viledCleaningSolution",
  },
  {
    id: uuidv4(),
    title: "Video Laryngoscope",
    img: require("../../asset/videoLarnyascope.jpg"),
    url: "/videoLaryngoscope",
  },
  {
    id: uuidv4(),
    title: "Suction Machine",
    img: require("../../asset/products/SuctionMachine/suctionMachine1.jpg"),
    url: "/suctionMachine",
  },

  {
    id: uuidv4(),
    title: "OT Lights",
    img: require("../../asset/products/OT/Lights/OTLights1.JPG"),
    url: "/oTLights",
  },
  {
    id: uuidv4(),
    title: "OT Tables",
    img: require("../../asset/products/OT/Tables/OTTable1.JPG"),
    url: "/oTTables",
  },
  {
    id: uuidv4(),
    title: "AutoClave",
    img: require("../../asset/products/AutoClave/autoclave3.jpeg"),
    url: "/autoClave",
  },
  {
    id: uuidv4(),
    title: "Ozone Fumigation System",
    img: require("../../asset/products/OzoneFumigation.jpg"),
    url: "/ozoneFumigationSystem",
  },
];
