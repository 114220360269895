const { v4: uuidv4 } = require("uuid");
export const MajorProduct = [
  {
    id: uuidv4(),
    title: "Modular PM Complex",
    img: require("../../asset/SubProductImages/modular PM complex.jpg"),
    description: "covid 19 product",
    url: "/ModularPMComplex",
  },
  {
    id: uuidv4(),
    title: "Modular ICU / NICU ",
    img: require("../../asset/products/iCUandNICU.jpg"),
    description: "covid 19 product",
    url: "/ModularIcu",
  },
  {
    id: uuidv4(),
    title: "Modular Labour Room ",
    img: require("../../asset/SubProductImages/modularLabourRoom.jpg"),
    description: "covid 19 product",
    url: "/modularLabourRoom",
  },
  {
    id: uuidv4(),
    title: "Modular Operation theater",
    img: require("../../asset/products/operationTheather.jpg"),
    description: "covid 19 product",
    url: "/modularOperationTheater",
  },
  {
    id: uuidv4(),
    title: "Oxygen generation Plant (PSA)",
    img: require("../../asset/products/psaOxygenGeneration.jpg"),
    description: "covid 19 product",
    url: "/psa",
  },
  {
    id: uuidv4(),
    title: "Liquid Medical Oxygen Plant (LMO)",
    img: require("../../asset/products/liquidOxygen.jpg"),
    description: "covid 19 product",
    url: "/liquidOxygenPlant",
  },
  {
    id: uuidv4(),
    title: "Medical Gas Pipeline System (MGPS)",
    img: require("../../asset/products/MGPS.jpg"),
    description: "covid 19 product",
    url: "/MGPS",
  },
  {
    id: uuidv4(),
    title: "Hospital Linens Laundry Machines",
    img: require("../../asset/products/laundrySystem.jpg"),
    description: "covid 19 product",
    url: "/laundryMachine",
  },
  {
    id: uuidv4(),
    title: "Effuent/Sewage water Treatment",
    img: require("../../asset/SewageaWaterBanner.jpg"),
    description: "covid 19 product",
    url: "/waterTreatment",
  },
 
  {
    id: uuidv4(),
    title: "Video Laryngoscope",
    img: require("../../asset/videoLarnyascope.jpg"),
    description: "covid 19 product",
    url: "/videoLaryngoscope",
  },
  {
    id: uuidv4(),
    title: "Vileda Hospital Cleaning Solution",
    img: require("../../asset/products/hospitalCleaning.jpg"),
    description: "covid 19 product",
    url: "/viledCleaningSolution",
  },
  {
    id: uuidv4(),
    title: "Ozone Fumigation System",
    img: require("../../asset/products/OzoneFumigation.jpg"),
    description: "covid 19 product",
    url: "/ozoneFumigationSystem",
  },
];
