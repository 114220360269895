import React from "react";
import { useParams } from "react-router";
import { allProductDescription } from "../../data/Products/AllProductDescription";
import HeadingType1 from "../HeadingType1";

export default function InfoProductDescription() {
  const param = useParams();
  const data = allProductDescription;
  let obj = data.filter((d) => d.id === param.id);

  const MinorClickDescription = () => (
    <div class="mb-4">
      <div class="row">
        <div class="col-md-8" >
          <h4>Standard Features :</h4>
          <ul>
            {obj[0]?.features.map((feature) => (
              <li class="h6">{feature}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div class="mt-4 row" style={{ paddingTop: "6%" }}>
        <HeadingType1 data={obj[0]?.id} />
      </div>
      <div class=" make-center" style={{ backgroundColor: "#F7F7F7", padding:"10px",height:"300px" }}>
        <div class="row make-center " style={{ width: "70%" }}>
          <div class="col-md-12">
            <div class="make-center h4 mt-4 p-4">More description</div>
            <div class="make-center">{obj[0]?.description}</div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div class="make-center">
        <div class="row" style={{ width: "80%" }}>
          <MinorClickDescription />
        </div>
      </div>
      <div style={{ backgroundColor: "#F7F7F7", padding:"10px" }}>
        <div class="make-center h3 mb-4 pt-4">Samples</div>
        <div class="make-center "style={{width:"100%"}} >
          <div
            class="row "
            style={{
              display: "flex",
              justifyContent: "space-around",
              paddingTop:"4%"
            }}
          >
            {obj[0]?.images.map((image) => (
              <div style={{paddingLeft:"5Px",display:"flex",width: "300px",marginLeft:"30px"}}>
                  <img
                src={image}
                class=" img-rounded img-thumbnail mb-4"
                alt={image}
              />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
