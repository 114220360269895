import React from "react";
import "./css/AboutPage.css";

export default function AboutPageCards({ data }) {
  return (
    <div class="col-sm-6 mb-4">
      <div class="card border-0" style={{ minHeight: "25rem" }}>
        <div class="card-body aboutcard-box-shadow p-4">
          <h5 class="card-title about-title">{data.title}</h5>
          <p class="card-text content-style">{data.content}</p>
        </div>
      </div>
    </div>
  );
}
