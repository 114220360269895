import React from "react";

export default function HeroWallpaper({ data }) {
  console.log("Data", data.gifURL);
  return (
    <div>
      <div
        class="card "
        style={{
          marginTop: "65px",
          backgroundColor: "#61A3BA",
        }}
      >
        <div style={{ overflow: "hidden", height: "20rem" }}>
          <div>
            <div
              class="make-center h4"
              style={{
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <a style={{ textDecoration: "none" }} href="/">
                <div style={{ color: "white", fontSize: "3rem" }}>
                  ~ /{data?.title}
                </div>
              </a>
              <div>
                <iframe title={data.id} src={data.gifURL} style={{ height: "400px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
