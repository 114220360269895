import React from "react";
import { heroWallpaper } from "../data/heroWallpaper";
import { imageTitleCard } from "../data/imageTitleCards";
import HeadingType1 from "./HeadingType1";
import HeroWallpaper from "./HeroWallpaper";
import ImageTitleCard from "./ImageTitleCard";

export default function Quality() {
  const data = imageTitleCard.qualityImageCard;
  const heroWallpaperData = heroWallpaper
  return (
    <div>
      <HeroWallpaper data={heroWallpaperData.qualityHeroWallpaper} />
      <div class="container mt-5">
        <HeadingType1 data="qualityPageHeading" />
        <div class="row">
          {data.map((info) => (
            <ImageTitleCard data={info} />
          ))}
        </div>
      </div>
    </div>
  );
}
