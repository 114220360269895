export const headingtype1 = {
  ourStory: {
    title: "THIS IS OUR STORY",
    subtitle: "It started with a simple idea & our mission remains the same.",
    content: `
    Laxmi Surgicals and Pharma has embarked on a remarkable journey rooted in a simple yet profound idea, and our unwavering mission remains unchanged. For over 18 years, we have been at the forefront of the medical industry, offering a comprehensive range of Lamax brand surgical instruments, medical equipment, and services vital for hospitals, particularly in operation theatres.
Headquartered in Jalgaon, Maharashtra, our firm, Laxmi Surgicals & Pharma, has established a formidable presence across the entire state, diligently serving every district since our inception in 2003. We have earned widespread recognition for our commitment to manufacturing and supplying Lamax surgical instruments and equipment tailored to meet the diverse needs of various surgical specialties.

With extensive experience in both manufacturing and service aspects of the surgical instrument and medical equipment sector, we have gained invaluable insights into the unique requirements and expectations of our clientele. Our ability to seamlessly adapt and cater to individual needs has garnered the trust and confidence of clients worldwide.

Throughout our journey, we have remained steadfast in our dedication to excellence, reliability, and customer satisfaction, ensuring that our mission resonates in every endeavor we undertake

    `,
  },
  managementTeam: {
    title: "MANAGEMENT TEAM",
    subtitle: `The experienced and assertive team, at Laxmi Surgicals, is
              led by management that has a blend of strategic, operational and
              financial expertise.our leaders share extensive and
              long-established relationships with associates.`,
  },
  qualityPolicy: {
    title: "QUALITY POLICY",
    content: `We believe in providing quality of highest level. The fact that we
            are in a business which is directly linked to the health and well
            being of the people makes us more responsible company, and no matter
            what, no compromises are made. Also, rigorous quality assurance
            resources ensure stricter quality control.`,
  },
  carouselPage: {
    title: "What WE BELIEVE IN",
    subtitle: "The organic reviews not hoax.",
  },
  qualityPageHeading: {
    title: "Laxmi Surgicals - 'QUALITY OBSESSED'",
    subtitle: `
Today’s excellence is inevitably tomorrow’s mediocrity. We constantly push the limits of
excellence to make sure that our work is the best it can be. Doing a good job is not good
enough. We draw upon our expertise and passion to go above and beyond to exceed
expectations for clients`,
  },
  ourProductHomePage: {
    title: "OUR PRODUCTS",
    subtitle:
      "Laxmi surgicals products are ISO 9001 and CE certified and we have been recognized as Star Export House by Government of India.",
  },
  aboutUsHomePage: {
    title: "ABOUT US",
    subtitle:
      "We are committed to providing the BEST quality, in-time delivery, and a very happy experience to our customers. No surprises on why we are growing at such an impressive rate!",
  },
  pricingPageDetails: {
    title: "Gallery",
    subtitle:
      "Help us to offer you our best quote for your requirement! Our pricing depends on quantity of products, shipping destination and freight.",
  },
  productPageDeatils: {
    title: "OUR PRODUCTS",
    content: `
      As a manufacturer, we not only manufacture standard
products, but also a range of services and technologies
that enable us to provide a bespoke manufacturing service.
In the last decade, our business has been evolving ever.
more towards Turnkey supply of Modular Operation Theaters,
Modular Labour Rooms, Modular ICU and NICU, Oxygen
Generation Plants. We equip some of the Government
Hospitals all over Maharashtra, smaller private healthcare
facilities, through to small private clinics and specialty centers
in India. Through precision engineering services we employ
the latest technologies to enable us to offer everything from
design, prototyping and on to full production runs. We can
assist with everything from a single component or device.
Our many years of experience makes us particularly skilled at
producing products that have the need for a high degree of
assembly and fabrication expertise from our makers.
      `,
  },

  contactUsPage: {
    title: "Contact Us",
    subtitle: `Do you have any questions? Please do not hesitate to contact us
            directly. Our team will come back to you within a matter of hours to
            help you.`,
  },
  ModularPMComplex: {
    title: "Modular PM Complex",
  },
  ModularIcu: {
    title: "Modular ICU / NICU",
  },
  modularLabourRoom: {
    title: "Modular Labour Room",
  },
  modularOperationTheater: {
    title: "Modular Operation theater",
  },
  psa: {
    title: "Oxygen generation Plant (PSA)",
  },
  BoosterPlant: {
    title: "Booster Plant for PSA",
  },
  liquidOxygenPlant: {
    title: "Liquid Medical Oxygen Plant (LMO)",
  },
  MGPS: {
    title: "Medical Gas Pipeline System (MGPS)",
  },

  laundryMachine: {
    title: "Hospital Linens Laundry Machines",
  },
  waterTreatment: {
    title: "Effuent/Sewage water Treatment",
  },
  iopMeasurment: {
    title: "IOP Measurment",
  },
  videoLaryngoscope: {
    title: "Video Laryngoscope",
  },
  viledCleaningSolution: {
    title: "Vileda Hospital Cleaning Solution",
  },
  ozoneFumigationSystem: {
    title: "Ozone Fumigation System",
  },
  oTLights: {
    title: "Operation Theater Lights",
  },
  oTTables: {
    title: "Operation Theater Tables",
  },
  suctionMachine: {
    title: "Suction Machine",
  },
  autoClave: {
    title: "Auto Clave",
  },
  CSSD: {
    title: "Central Sterile Supply Department (CSSD)",
  },
};
