import { Card } from "@material-ui/core";
import {
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Product = ({ product }) => {
  const navigate = useNavigate();

  return (
    <Col xs={12} md={4} lg={3} key={product.id} className="product-card">
      <motion.div
        whileHover={{ scale: 1.2 }}
        onHoverStart={(e) => {}}
        onHoverEnd={(e) => {}}
        whileFocus={{ scale: 0.1 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", duration: 1 }}
        initial={{ scale: 0 }}
      >
        <Card
          sx={{ maxWidth: 10 }}
          onClick={() => navigate(`/PRODUCT${product.url}`)}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="160"
              image={product.img}
              alt={product.title}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                className="make-center"
              >
                {product.title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </motion.div>
    </Col>
  );
};

export default Product;
