import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import "../../components/Gallery/ImgGrid/GalleryImageGri.css"
import { motion } from "framer-motion";

const itemData = [
  {
    img: require("../../asset/Gallery/GalleryImages/gi14.jpg"),
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi12.jpg"),
    cols: 2,
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi11.jpg"),
    rows: 2,
    cols: 2,
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi18.jpg"),
  },

  {
    img: require("../../asset/Gallery/GalleryImages/gi6.jpg"),
    rows: 2,
    cols: 2,
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi7.jpg"),
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi4.jpg"),
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi8.jpg"),
    cols: 2,
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi16.jpg"),
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi9.jpg"),
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi1.jpg"),
    cols: 2,
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi15.jpg"),
    rows: 2,
    cols: 2,
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi17.jpg"),
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi13.jpg"),
  },
  {
    img: require("../../asset/Gallery/GalleryImages/gi3.jpg"),
    cols: 2,
  },
];

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function GalleryImageGrid() {
  return (
    <ImageList sx={{ width: 1200 }} variant="quilted" cols={3} rowHeight={161}>
      {itemData.map((item) => (
        <motion.div whileHover={{ scale: 1.1 }}>
          <ImageListItem key={item.img} className="grid-img">
            <img
              {...srcset(item.img,  item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
              className="img-style"
            />
          </ImageListItem>
        </motion.div>
      ))}
    </ImageList>
  );
}



  /* <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem> */
