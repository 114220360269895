export const imageTitleCard = {
  qualityImageCard: [
    {
      title: "Certificate of Recognition",
      imageURL: require("../asset/certificate2.jpeg"),
      pdf: require("../asset/Certificates/recognitionCertificate.pdf"),
    },
    {
      title: "Importer-Exporter Code",
      imageURL: require("../asset/certificate2.jpeg"),
      pdf: require("../asset/Certificates/IceCode.pdf"),
    },
    {
      title: "Entity Identifier",
      imageURL: require("../asset/certificate2.jpeg"),
      pdf: require("../asset/Certificates/LSLEI.pdf"),
    },
    {
      title: "Udyam Registraion Certificate",
      imageURL: require("../asset/certificate2.jpeg"),
      pdf: require("../asset/Certificates/SmallUdyamRegistrationCertificate.pdf"),
    },
    {
      title: "ISO",
      imageURL: require("../asset/certificate2.jpeg"),
      pdf: require("../asset/Certificates/ISO.pdf"),
    },
    {
      title: "CVC",
      imageURL: require("../asset/certificate2.jpeg"),
      pdf: require("../asset/Certificates/CVC Certificate LSP.jpg"),
    },
  ],
};
