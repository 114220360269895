import { createBrowserRouter } from "react-router-dom";
import OurProducts from "../src/pages/OurProducts";
import AboutPage from "./components/AboutPage";
import ContactInfo from "./components/ContactInfo";
import ContactUsPage from "./components/ContactUsPage";
import MainNavigation from "./components/Layout/MainNavigation";
import PricingPage from "./components/PricingPage";
import InfoProductDescription from "./components/Product/InfoProductDescription";
import ProductsPage from "./components/Product/ProductsPage";
import QualityPage from "./components/QualityPage";
import ErrorPage from "./pages/ErrorPage";

export default createBrowserRouter([
  {
    path: "/",
    element: <MainNavigation />,
    children: [
      {
        index: true,
        element: <OurProducts />,
      },
      {
        path: "/ABOUT",
        element: <AboutPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/CERTIFICATES",
        element: <QualityPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/Gallery",
        element: <PricingPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/CONTACT",
        element: <ContactUsPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/CONTACTINFO",
        element: <ContactInfo />,
        errorElement: <ErrorPage />,
      },
      { path: "/PRODUCT/:id", element: <InfoProductDescription /> },
      {
        path: "/PRODUCT",
        element: <ProductsPage />,
        errorElement: <ErrorPage />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);
