export const allProductDescription = [
  {
    id: "ModularPMComplex",
    name: "swapnil",
    description: `Need Basic facilities for the departed soul, public and official working there.
From overall expenditures of any hospital, a very minute share
is spent on th	utopsy facility. Working in a Mortuary is an extremely stressful experience due to Ian e numbers of people dying sudden violent deaths due to trauma.
Complicated cases requiring re-examination are further referred to tertiary level hospitals, where autopsy work is conducted by the department of forensic Medicine.
Keeping in view these points we at Laxml Surgicals
present a layout for the ideal Mortuary Complex designed for Medical Colleges, Disrtrict Hospitals and Peripheral Hospitals`,
    features: [
      "Specimen handling area",
      `Administrative functions`,
      "Receiving, preparation & temporary storage of cadavers",
      `Demonstration of PM for teaching orforensic purposes`,
      `Family / police viewing gallery`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/Motuary Complex/Picture1.jpg"),
      require("../../asset/SubProductImages/Motuary Complex/Picture2.jpg"),
    ],
  },
  {
    id: "ModularIcu",
    name: "swapnil",
    description: `Need Basic facilities for the departed soul, public and official working there.
From overall expenditures of any hospital, a very minute share
is spent on th	utopsy facility. Working in a Mortuary is an extremely stressful experience due to Ian e numbers of people dying sudden violent deaths due to trauma.
Complicated cases requiring re-examination are further referred to tertiary level hospitals, where autopsy work is conducted by the department of forensic Medicine.
Keeping in view these points we at Laxml Surgicals
present a layout for the ideal Mortuary Complex designed for Medical Colleges, Disrtrict Hospitals and Peripheral Hospitals`,
    features: [
      `Writing List Board Hermetically Sealed Doors Storage Cabinets`,
      `Surgical Scrub Stations ICU Instruments Cabinet ICU Trolleys`,
      `ICU Crash Cart ICU Beds`,
      `ICU Bed Side Lockers`,
      `NICU Baby Warmers/Phototherapy Pressure Module-Negative & Positive Isolation RoomsAir `,
      `Filtration Systems`,
      `Fan Filter Uni`,
      `Bacteria Resistance Paint Coatings Vinyl Floorings`,
      `Ceiling Pendant System`,
      `Track Curtain Partition System`,
      `X-ray Films Viewers Peripheral Lights`,
      `Modular Wall & Ceiling panels`,
      `Laminar Air Flow`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/ModularICUandNICU/Picture15.png"),
      require("../../asset/SubProductImages/ModularICUandNICU/Picture16.png"),
      require("../../asset/SubProductImages/ModularICUandNICU/Picture17.jpg"),
      require("../../asset/SubProductImages/ModularICUandNICU/Picture18.jpg"),
      require("../../asset/SubProductImages/ModularICUandNICU/Picture19.jpg"),
      require("../../asset/SubProductImages/ModularICUandNICU/Picture20.png"),
      require("../../asset/SubProductImages/ModularICUandNICU/Picture21.jpg"),
    ],
  },
  {
    id: "modularLabourRoom",
    name: "swapnil",
    description: `A modular labor room refers to a labor and delivery room in a healthcare facility that is constructed using modular building techniques. Modular construction involves assembling components or entire rooms off-site in a controlled factory environment and then transporting them to the final location for installation. This approach offers several advantages in terms of efficiency, flexibility, and speed of construction`,
    features: [
      `Wall panelling systems`,
      `Ceiling panelling system`,
      `Sterile	and coating systems`,
      `Anti static flooring`,
      `EGP puff double door`,
      `Peripheral lighting`,
      `Bio-air conditioning HVAC system clean room`,
      `Air purification system`,
      `Video laryngoscope`,
      `Air curtain`,
      `Diagnostic hysteroscopy with Endoscopic works station`,
      `Electrical installations`,
      `Transport & other work`,
      `Installation on turn key basis`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/ModularLabourRoom/Picture22.png"),
      require("../../asset/SubProductImages/ModularLabourRoom/Picture23.png"),
      require("../../asset/SubProductImages/ModularLabourRoom/Picture24.jpg"),
      require("../../asset/SubProductImages/ModularLabourRoom/Picture25.jpg"),
    ],
  },
  {
    id: "modularOperationTheater",
    name: "swapnil",
    description: `
A modular operation theater (OT) is a specialized facility designed for surgical procedures. The importance of a modular operation theater lies in its ability to provide a controlled and sterile environment for surgeries, contributing to the overall success and safety of medical procedures. Here are some key aspects highlighting the importance of modular operation theaters:`,
    features: [
      `fadf`,
      `Pendants`,
      `Writing Board`,
      `Hermetic Sealed Automatic/ ManualDoors`,
      `O.T. Control Panel`,
      `Distribution Board`,
      `O.T. Table & Furniture`,
      `Other Accessories`,
      `Plenum Unit (Laminar Air Flow)`,
      `The Wall Panel`,
      `Ceiling Panel`,
      `Antistatic Flooring`,
      `Scrub Sink`,
      `Peripheral & O.T. Lights`,
      `Bed Head Panels`,
      `ressure Relif Damper`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/ModularOperationTheater/1.jpg"),
      require("../../asset/SubProductImages/ModularOperationTheater/2.jpg"),
      require("../../asset/SubProductImages/ModularOperationTheater/3.jpg"),
      require("../../asset/SubProductImages/ModularOperationTheater/4.jpg"),
      require("../../asset/SubProductImages/ModularOperationTheater/5.jpg"),
      require("../../asset/SubProductImages/ModularOperationTheater/6.jpg"),
      require("../../asset/SubProductImages/ModularOperationTheater/7.jpg"),
      require("../../asset/SubProductImages/ModularOperationTheater/8.jpg"),
    ],
  },
  {
    id: "psa",
    name: "swapnil",
    description: `Laxmi surgical on-site PSA oxygen plants are based on the well-known PSA technology (pressure Swing Adsorption). Two pressurised
vessels with zeolite ensure continuous production for seperation of oxygen from other gases.
LAMAX O2 generator consist of two pressure vessesls storing zeolite.
Our PSA oxygen generators can produce Oxygen purities between 92-95%`,
    features: [
      `PSA Plant Capacity Calculation`,
      `20 Nm3 = 330 LPM = 20000 Litres/HOUR = 480000 Litres/DAY = 0.678 metric ton/day capacity`,
      `30 Nm3 = 500 LPM = 30000 Litres/HOUR = 720000 Litres/Day = 1.076 metric ton/day capacity`,
      `60 Nm3 = 1000 LPM = 60000 Litres/HOUR = 1440000 Litres/DAY = 2.035 metric ton/Day`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/psaOxygen/1.jpg"),
      require("../../asset/SubProductImages/psaOxygen/2.jpg"),
      require("../../asset/SubProductImages/psaOxygen/3.jpg"),
      require("../../asset/SubProductImages/psaOxygen/4.jpg"),
      require("../../asset/SubProductImages/psaOxygen/5.jpg"),
      require("../../asset/SubProductImages/psaOxygen/6.jpg"),
    ],
  },
  {
    id: "liquidOxygenPlant",
    name: "swapnil",
    description: `We know 65% of human body is oxygen. Yes, oxygen is
vital for respiration, the process that transfers energy from
glucose to cells. Inyfact, every cell in our body requires oxygen.
When we breathe air in, oxygen molecules enter the lungs and
pass through King walls into our blood. Due to its low melting
and boiling points, oxygen is in a gaseous state at room
temperature. Liquification enables storage in larger volume
and easier transportation. Fractional Distillation Method to
produce_ pure oxygen from atmospheric air, which consists
mostly of nitrogen and oxygen - 78% nitrogen, 21% oxygen
and remaining 1% other gases including argon, carbon dioxide,
neon, helium, and hydrogen. Cryogenic liquid containers are
specially designed for safe and economic transportation and
storage of liquified gases at cryogenic temperatures, lower
than -90°C. These containers are highly insulated, in which
liquid gases are stored at very low temperature.`,
    features: [],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/liquidMedicalOxygen/1.jpg"),
      require("../../asset/SubProductImages/liquidMedicalOxygen/2.jpg"),
      require("../../asset/SubProductImages/liquidMedicalOxygen/3.jpg"),
      require("../../asset/SubProductImages/liquidMedicalOxygen/4.jpg"),
    ],
  },
  {
    id: "MGPS",
    name: "swapnil",
    description: `A central oxygen system in a healthcare setting refers to a system designed to provide a centralized and continuous supply of medical-grade oxygen to various areas within a hospital or healthcare facility. This system ensures that medical staff have easy access to oxygen for patient care, emergency situations, surgeries, and other medical procedures. Here are key components and considerations associated with a central oxygen system:`,
    features: [
      `Medical Gas Outlets`,
      `Ceiling Mounted Pendants For Dt's`,
      `Bed Head Panels`,
      `Medical Gas Area Alarm System`,
      `Accessories`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/MGPS/Picture26.jpg"),
      require("../../asset/SubProductImages/MGPS/Picture27.jpg"),
      require("../../asset/SubProductImages/MGPS/Picture28.jpg"),
    ],
  },
  {
    id: "laundryMachine",
    name: "swapnil",
    description: `NA`,
    features: [
      `Commercial washing & processing machine front loaded`,
      `Commercial washing & tenctile processing machine side/front loading`,
      `Commercial washer extractor`,
      `Hydro extractor`,
      `Industrial drying tumbler`,
      `Washer extractor`,
      `Stack washer and dryer`,
      `Flat bed press`,
      `Flat work drying ironer, heat chest`,
      `Vaccum finishing table`,
      `Steam generate manual and fully automatic`,
      `Body presses/form finisher`,
      `Apparel processing machine with resin spray attachments`,
      `Trolley, table & scrub station.`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/HospitalLaundry/Picture29.jpg"),
      require("../../asset/SubProductImages/HospitalLaundry/Picture30.jpg"),
      require("../../asset/SubProductImages/HospitalLaundry/Picture31.jpg"),
      require("../../asset/SubProductImages/HospitalLaundry/Picture32.jpg"),
    ],
  },
  {
    id: "waterTreatment",
    name: "swapnil",
    description: `Keeping In view of very high cost for installation of STR & ETP separately for any
industry, Laxmi Surgicals Offers treatment of both sewage & effluent. CETP reduces
the project cost as well as the area required for plant establishment. We "Laxmi Surgicals"
offer complete solution of work including Design, Drawing, Engineering, Construction,
Fabrication, Renovation, Supply, Erection, Testing & Commissioning including Mechanical,
Electrical, Piping and Instrumentation items for CETP's for different industries & hospitals
on Turnkey basis.`,
    features: [
      `Simple design maximizes reliability and efficiency`,
      `Multi layers of filtration media`,
      `Standard and effective quartz sand media`,
      `Low pressure drops across the vessel`,
      `Manual, semi-automatic and automatic features are provided`,
      `Pre-assembled construction minimizes start-up time and installation costs`,
      `Structural steel legs provide rigid support of vessel for safety`,
      `Air scouring available for high flow pressure vessel`,
      `Internal distribution and collection system`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/EffluentTreatementPlant/Picture33.jpg"),
      require("../../asset/SubProductImages/EffluentTreatementPlant/Picture34.jpg"),
    ],
  },
  {
    id: "iopMeasurment",
    name: "swapnil",
    description: `We asked hundreds of eye care specialists-there is no 100% accurate technique of IOP mesurement. We offer Easyton -a new method, which provides result most close to the true intracameral IOP`,
    features: [
      `Opthalmology`,
      `Optometry`,
      `Orthoptics`,
      `Fast Screening any place`,
      `Can be used by the most of eye care professional`,
      `Can be used when the corneal tonometry is contraindicated`,
      `Safe to the cornea`,
      `Convenient for the doctor, gentle to patients`,
      `Cost effective`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/MGPS/Picture26.jpg"),
      require("../../asset/SubProductImages/MGPS/Picture27.jpg"),
      require("../../asset/SubProductImages/MGPS/Picture28.jpg"),
    ],
  },
  {
    id: "videoLaryngoscope",
    name: "swapnil",
    description: `Feature Rich Video Laryngoscope
Intubation procedure require skills and secondary confirmation device to
ensure correct positioning of the tube
LAMAX Video laryngoscope is a dependable device that helps
anesthesiologist to carry out the complex procedure withgase.
The product looks like a normal laryngoscope retaining all the
advantages.
But what makes it class apart is the small camera fitted on it.
One tiny addition that makes a big difference.`,
    features: [
      `Waterproof camera`,
      `CMOS Sensor`,
      `Inbuilt LED Illumination`,
      `Video recording/Image storing`,
      `Instant playback`,
      `Easy data storage on MicroSD card`,
      `3.5 inch multifunction screen`,
      `Specially designed concave blade`,
      `180 ° rotating LCD screen`,
      `Multiple blades option of different types & sizes`,
      `Downloading option`,
      `AV out on TV/AV monitor`,
      `Strong perfect size handle with coating`,
      `Steel blades`,
      `One year warranty`,
      `Neonatal, Paediatric and Adult Blades sets available`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/VideoLarngoscope/Picture35.jpg"),
      require("../../asset/SubProductImages/VideoLarngoscope/Picture36.jpg"),
      require("../../asset/SubProductImages/VideoLarngoscope/Picture37.jpg"),
      require("../../asset/SubProductImages/VideoLarngoscope/Picture38.png"),
    ],
  },
  {
    id: "viledCleaningSolution",
    name: "swapnil",
    description: `Description:
0.9 beds per 1000 population (Global
average 2.9 beds)
Hospitals in India have a high burden
of infections in their intensive care units
(ICU) and general wards, many of
which are resistant to antbiotic
treatment
For India, incidence rate varies from
11% to 83% for different kinds of HAls
Alarming!!!`,
    features: [
      `Wiping`,
      `Scouring`,
      `Floor Cleaning`,
      `Protection and Care`,
      `Waste Management`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/ViledaHostipalCleaning/1.jpg"),
      require("../../asset/SubProductImages/ViledaHostipalCleaning/2.jpg"),
      require("../../asset/SubProductImages/ViledaHostipalCleaning/3.jpg"),
      require("../../asset/SubProductImages/ViledaHostipalCleaning/4.jpg"),
      require("../../asset/SubProductImages/ViledaHostipalCleaning/5.jpg"),
    ],
  },
  {
    id: "ozoneFumigationSystem",
    name: "swapnil",
    description: `Ozone fumigation systems utilize ozone gas (O3) for the purpose of disinfection, deodorization, and sanitization in various environments. Ozone is a powerful oxidizing agent with the ability to eliminate bacteria, viruses, fungi, and other pathogens. The process involves generating ozone and distributing it within a space to achieve the desired effects.`,
    features: [
      `Ozone (03) is a tri-atomic molecule consisting of three oxygen atoms.`,
      `It is an allotrope/of oxygen that is much less stable than moleculap/oxygen.`,
      `Extremely effective disinfectant, viricide and bactericide.`,
      `Made and used at site avoiding any chemical storage.`,
      `One of the most powerful oxidizing agent.`,
      `No persistent chemical addition - decomposes to O2.This prevents the formation of all the harmful chlorinated organics and hence, the negative effects of chlorine compounds are prevented.`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/SubProductImages/ozoneFumigation/1.jpg"),
      require("../../asset/SubProductImages/ozoneFumigation/2.jpg"),
      require("../../asset/SubProductImages/ozoneFumigation/3.jpg"),
    ],
  },
  {
    id: "CSSD",
    name: "swapnil",
    description: `Sterilizers are designed to be used with ethylene oxide gas cartridge to provide a safe and effective low temperature sterilization process for hospitals ,Cath labs, Medical institutions, eye specialists, ortho specialists, General Surgery centers,gynec care units ,cardio Thoracic surgeon, interventional cardiologists, plastic /Cosmetic surgeons, Neuro Surgeons, Research Centers, Specialty Laboratories ,Pharmaceuticals applications.
To achieve the objective of delivering quality healthcare at an affordable price and to guarantee a high level of infection control, sterilization of critical medical devices is of paramount importance.
EO Gas Sterilizer plays an important role to achieve sterilization at low temperature.`,
    features: [
      `Cabinet type Sterilizer with elegant look, less space requirement for installation.`,
      `The machine is constructed with heavy duty Stainless steel 304/316.`,
      `Specially designed for uniform temperature in the chamber.`,
      `Complete Process will be in negative Pressure.`,
      `Automatic vacuum.`,
      `Automatic Humidification.`,
      `Temp. Control for uniform Temperature.`,
      `Automatic gas injection.`,
      `Process phase indicator during the Process`,
      `Automatic Degassing & Aeration`,
      `Online Printing (Time,Temp,Pressure,RH,Phase indication)`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/products/CSSD/cssd1.png"),
      require("../../asset/products/CSSD/cssd2.png"),
      require("../../asset/products/CSSD/cssd3.png"),
    ],
  },
  {
    id: "suctionMachine",
    name: "swapnil",
    description: `A suction machine, also known as an aspirator, is a type of medical device that is primarily used for removing obstructions — like mucus, saliva, blood, or secretions — from a person’s airway. When an individual is unable to clear secretions due to a lack of consciousness or an ongoing medical procedure, suction machines help them breathe by maintaining a clear airway.`,
    features: [
      `A power supply (for some machines, a 12-V DC automotive cable will also suffice if external power is available)`,
      `A disposable collection canister`,
      `Aspirator-to-canister connective tubing`,
      `Patient tubing (also known as the aspirator circuit)`,
      `A suction catheter or surgical suction accessory (the appropriate accessory depends on the procedure)`,
      `Spill control solidifiers to safely dispose of liquid biohazards`,
      `Any additional accessories that are required for the procedure and approved by the device manufacturer`,
      `The Suction unit runs on oil free rocking piston vacuum pump and thermally protected vacuum pump motor.`,
      `The unit having stainless steel cabinet mounted on antistatic castor wheels.`,
      `The suction unit is provided with two Autoclavable/Unbreakable collection jars made from high quality polycarbonate material.`,
      `The collection jars have self sealing rubber lids.`,
      `Vacuum can be adjusted with the help of vacuum regulator and vacuum gauge.`,
      `Safety float prevents entry of suction fluid in the pump in case of overfilling.`,
      `A special bacteria filter ensures zero contamination and thus prevents infection.`,
      `The unit can also be operated ON/OFF by foot switch with the help of foot switch arrangement.`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/products/SuctionMachine/suctionMachine1.jpg"),
      require("../../asset/products/SuctionMachine/suctionMachine2.jpg"),
      require("../../asset/products/SuctionMachine/suctionMachine3.jpg"),
    ],
  },
  {
    id: "oTLights",
    name: "swapnil",
    description: `Operating Theatre Lights are the ultimate lighting solution for surgical environments. These state-of-the-art lights combine advanced technology with exceptional functionality, providing surgeons and medical professionals with unparalleled visibility and precision. With their high luminous efficiency and uniform light distribution, these lights ensure optimal illumination throughout the operating theater, minimizing shadows and enhancing surgical accuracy. The LED 9 lights are equipped with adjustable color temperature, allowing surgeons to select the most suitable lighting for different procedures. Their energy-efficient design reduces power consumption, contributing to a greener and more sustainable healthcare setting. Designed to meet the highest safety standards, these lights are easy to clean and maintain, ensuring a sterile and hygienic operating environment. Elevate your surgical practice with the superior performance of the LED 9 Operating Theatre Lights.`,
    features: [
      `Table top can horizontal sliding, Precise scale display, Direct Manipulation.`,
      `Table top plate use high transmittance materials. can use for C-arm. Optional X-ray board can use for X-ray.`,
      `Two joint gas spring control head plate is quick-detachable.`,
      `Gas spring control back plate and leg plate, leg plate can easily detachable.`,
      `Two section back plate design can replace the kidney bridge function.`,
      `T shape base provide the doctor enough space for standing. Foot pedal brake ,easy to operate.`,
      `Manual hydraulic elevation system, easy to control tabletop elevation.`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/products/OT/Lights/OTLights1.JPG"),
      require("../../asset/products/OT/Lights/OTLights2.JPG"),
      require("../../asset/products/OT/Lights/OTLights3.JPG"),
    ],
  },
  {
    id: "oTTables",
    name: "swapnil",
    description: `
In surgical settings, the demand for precise and reliable patient positioning is paramount, particularly in lateral procedures across multiple medical disciplines. The recommended pads for lateral positioning surgery serve as essential tools in achieving and maintaining the desired patient posture, supporting the patient's body while allowing for unobstructed access to the surgical site. Their compatibility with surgical tables and support surfaces further underscores their versatility and adaptability, making them a valuable asset in orthopedic, neurosurgical, and spinal procedures, among others.`,
    features: [
      `Table top can horizontal sliding, Precise scale display, Direct Manipulation.`,
      `Table top plate use high transmittance materials. can use for C-arm. Optional X-ray board can use for X-ray.`,
      `Two joint gas spring control head plate is quick-detachable.`,
      `Gas spring control back plate and leg plate, leg plate can easily detachable.`,
      `Two section back plate design can replace the kidney bridge function.`,
      `T shape base provide the doctor enough space for standing. Foot pedal brake ,easy to operate.`,
      `Manual hydraulic elevation system, easy to control tabletop elevation.`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/products/OT/Tables/OTTable1.JPG"),
      require("../../asset/products/OT/Tables/OTTable2.JPG"),
      require("../../asset/products/OT/Tables/OTTable3.JPG"),
      require("../../asset/products/OT/Tables/OTTable4.JPG"),
      require("../../asset/products/OT/Tables/OTTable5.JPG"),
    ],
  },
  {
    id: "autoClave",
    name: "swapnil",
    description: `Autoclaves are also known as steam sterilizers, and are typically used for healthcare or industrial applications. An autoclave is a machine that uses steam under pressure to kill harmful bacteria, viruses, fungi, and spores on items that are placed inside a pressure vessel. The items are heated to an appropriate sterilization temperature for a given amount of time. The moisture in the steam efficiently transfers heat to the items to destroy the protein structure of the bacteria and spores.`,
    features: [
      `Steam generators and steam supply`,
      `The pre and post vacuum phase of the sterilization cycle`,
      `The autoclave jacket`,
      `The cooling process`,
      `The autoclave control systems`,
    ],
    imageURL: "..dkanfkaj",
    images: [
      require("../../asset/products/AutoClave/autoclave1.jpg"),
      require("../../asset/products/AutoClave/autoClave2.jpg"),
      require("../../asset/products/AutoClave/autoclave3.jpeg"),
    ],
  },
];
