import { ThemeProvider } from "@mui/material/styles";
import { lightMode } from "./Theme/theme";
import Layout from "./components/Layout/Layout";
import Routers from "./Routers";
import { RouterProvider } from "react-router-dom";

function App(props) {
  return (
    <div className="App">
      <ThemeProvider theme={lightMode}>
        <Layout>
          <RouterProvider router={Routers} />
        </Layout>
      </ThemeProvider>
    </div>
  );
}

export default App;
