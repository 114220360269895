import { React, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import HeadingType1 from "./HeadingType1.jsx";
export default function ContactUsPage() {
  const userForm = useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('Form data:', formData);
    emailjs
      .sendForm(
        "service_70xrwrc",
        "template_vxj7qoh",
        userForm.current,
        "KFEOkcd8uP9XQ4J4F"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setFirstName("");
    setLastName("");
    setEmail("");
    setDescription("");
  };

  const handleChange = (e) => {
    setFirstName(e.target.from_name);
    setLastName(e.target.lastName);
    setEmail(e.target.user_email);
    setDescription(e.target.message);
  };

  return (
    <div>
      <div class="container p-5">
        <section class="mb-3">
          <div class="mb-5" style={{ paddingTop: "4%" }}>
            <HeadingType1 data="contactUsPage" />
          </div>
          <div class="container p-5" style={{ backgroundColor: "#F7F7F7" }}>
            <form
              ref={userForm}
              class="needs-validation"
              onSubmit={handleSubmit}
            >
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="firstName">First name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="from_name"
                    placeholder=""
                    value={firstName}
                    onChange={handleChange}
                    required
                  />
                  <div class="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="lastName">Last name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="lastName"
                    placeholder=""
                    value={lastName}
                    onChange={handleChange}
                    required
                  />
                  <div class="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  name="user_email"
                  placeholder="you@example.com"
                  value={email}
                  onChange={handleChange}
                />
                <div class="invalid-feedback">
                  Please enter a valid email address for shipping updates.
                </div>
              </div>
              <div class="mb-3">
                <label for="description">Product Description</label>
                <textarea
                  class="form-control"
                  name="message"
                  placeholder="My Product is....."
                  required
                  rows="4"
                  cols="50"
                  value={description}
                  onChange={handleChange}
                ></textarea>
                <div class="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>
              <button class="btn btn-primary btn-lg btn-block" type="submit">
                Send Enquiry
              </button>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}
