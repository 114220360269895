import React from "react";
import Carousel from "react-bootstrap/Carousel";
import ExampleCarouselImage from "./ExampleCarouselImage";

const CarouselWidget = () => {
  return (
    <div>
      <Carousel fade>
        <Carousel.Item interval={1000}>
          <ExampleCarouselImage height text="firstImage" />
          <Carousel.Caption>
            <h2 style={{ color: "#000", fontWeight: "600" }}>
              Liquid Oxygen plant
            </h2>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <ExampleCarouselImage text="secondImage" />
          <Carousel.Caption>
            <h2>Mortuary Complex</h2>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselWidget;
