import DownloadIcon from "@mui/icons-material/Download";
import React from "react";
import { projects, turnKeyProjects } from "../../data/Products/turnKeyProjects";
import { heroWallpaper } from "../../data/heroWallpaper";
import { InstrumentsData } from "../../data/instrumentData";
import HeadingType1 from "../HeadingType1";
import HeroWallpaper from "../HeroWallpaper";
import Product from "../Product";
import "./Products.css";

export default function ProductsPage() {
  const heroWallpaperData = heroWallpaper;
  const data = InstrumentsData;
  const openPdf = () => {
    window.open(data.pdf, "_blank");
  };
  return (
    <div>
      <HeroWallpaper data={heroWallpaperData.productsPageHeroWallpaper} />
      <div class="container mt-5 ">
        <HeadingType1 data="productPageDeatils" />
      </div>
      <div style={{ backgroundColor: "#F7F7F7" }} class="make-center">
        <div class="row container  mt-5">
          <div class=" make-center bold-title" style={{ marginBottom: "4%" }}>
            Turn Key projects
          </div>
          <div class="row">
            {turnKeyProjects.map((product) => (
              <Product key={product.id} product={product} />
            ))}
          </div>
        </div>
      </div>

      <div class="make-center">
        <div class="row container  mt-5">
          <div class=" make-center bold-title" style={{ marginBottom: "4%" }}>
            Products
          </div>
          <div class="row">
            {projects.map((product) => (
              <Product key={product.id} product={product} />
            ))}
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#F7F7F7" }} class="make-center">
        <div class="row container  mt-5">
          <div class=" make-center bold-title" style={{ marginBottom: "4%" }}>
            Instruments
          </div>
          <div
            class="row subtitle make-center mb-5"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="make-center">
              For more details click on Download Icon&nbsp;
              <button style={{ border: "none" }} onClick={openPdf}>
                <DownloadIcon color="primary" fontSize="large" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
