import React from "react";
import { aboutPageData } from "../data/aboutPage";
import AboutPageCards from "./AboutPageCards";
import HeadingType1 from "./HeadingType1";
import GalleryCarousel from "./Gallery/GalleryCarousel";
import "./css/AboutPage.css";
import { Avatar } from "@material-ui/core";

const ManagementCard = ({ data }) => {
  return (
    <div class="col-sm-4">
      <div class="card border-0" style={{ minHeight: "10rem" }}>
        <div class="card-body aboutcard-box-shadow p-4">
          <div class="headingCenter mb-5">
            {data?.img === "NO" ? (
              <Avatar style={{ height: "350px", width: "300px" }} />
            ) : (
              <img
                src={data.img}
                class="card-img-bottom"
                style={{ borderRadius: "100%", height: "350px" }}
                alt="abc"
              />
            )}
          </div>
          <h5 class="card-title headingCenter">{data.title}</h5>
          <p class="card-text headingCenter">{data.content}</p>
        </div>
      </div>
    </div>
  );
};

export default function AboutPage() {
  const data = aboutPageData;
  return (
    <div>
      <div class="about-wallpaper">
        <GalleryCarousel page="about" />
      </div>
      <div class="container p-5">
        <HeadingType1 data="ourStory" />
        <hr />
        <div class="row mt-4 mb-4">
          <div class="col-sm-12">
            <div class="card border-0" style={{ minHeight: "7rem" }}>
              <div class="card-body aboutcard-box-shadow p-4 ">
                <div class="card-title headingCenter about-title">
                  OUR MISSION
                </div>
                <div class="card-text headingCenter content-style">
                  Our mission is to create a major brand catering to both
                  Government as well as private institutions and also want to be
                  a recognized manufacturer / importer in the healthcare
                  industry. we want to be first choice for all their surgical
                  instrument and Equipment requirements and to deliver products
                  of outstanding quality, with great service, at an affordable
                  price.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          {data.cards.map((card) => (
            <AboutPageCards data={card} />
          ))}
        </div>
      </div>
      <div class="managementTeam">
        <div class="container p-5 mt-5">
          <HeadingType1 data="managementTeam" />
          <div
            class="row mb-5"
            style={{ width: "100%", justifyContent: "center" }}
          >
            {data.upperManagementCards.map((info) => (
              <ManagementCard data={info} />
            ))}
          </div>
          <div
            class="row mb-5"
            style={{ width: "100%", justifyContent: "center" }}
          >
            {data.managementCards.map((info) => (
              <ManagementCard data={info} />
            ))}
          </div>
        </div>
      </div>
      <div class="container p-5">
        <HeadingType1 data="qualityPolicy" />
      </div>
    </div>
  );
}
