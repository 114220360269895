import React from "react";
import AboutUs from "../components/AboutUs";
import HeadingType1 from "../components/HeadingType1.jsx";
import Product from "../components/Product";
import { MajorProduct } from "../data/Products/majorProduct.js";
import "./style.css";
import CarouselWidget from "../components/CarouselWidget.jsx";
import WelcomePage from "../components/WelcomePage.jsx";

export default function OurProducts() {
  const data = MajorProduct;
  return (
    <div>
      <div style={{ backgroundColor: "#F7F7F7" }}>
        <WelcomePage />
      </div>
      <div
        class="make-center p-4"
        style={{ display: "block", marginTop: "4%" }}
      >
        <div class="row mb-4  make-center" style={{ fontSize: "3rem" }}>
          Recently Launch Products
        </div>
        <hr />
        <div class="make-center">
          <div class="row mb-4 make-center mt-3" style={{ width: "90%" }}>
            <CarouselWidget />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#F7F7F7" }}>
        <div class=" container p-5">
          <div>
            <HeadingType1 data="ourProductHomePage" />
          </div>
          <div class="row">
            {data.map((product) => (
              <Product key={product.id} product={product} />
            ))}
          </div>
        </div>
      </div>
      <div class="container p-5 ">
        <div class="row">
          <AboutUs />
        </div>
      </div>
    </div>
  );
}
