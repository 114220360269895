import React from "react";
import { headingtype1 } from "../data/headingType1";

export default function HeadingType1({ data }) {
  let headingData = headingtype1;
  if (data === "ourStory") {
    headingData = headingtype1.ourStory;
  } else if (data === "managementTeam") {
    headingData = headingtype1.managementTeam;
  } else if (data === "qualityPolicy") {
    headingData = headingtype1.qualityPolicy;
  } else if (data === "carouselPage") {
    headingData = headingtype1.carouselPage;
  } else if (data === "qualityPageHeading") {
    headingData = headingtype1.qualityPageHeading;
  } else if (data === "ourProductHomePage") {
    headingData = headingtype1.ourProductHomePage;
  } else if (data === "aboutUsHomePage") {
    headingData = headingtype1.aboutUsHomePage;
  } else if (data === "pricingPageDetails") {
    headingData = headingtype1.pricingPageDetails;
  } else if (data === "productPageDeatils") {
    headingData = headingtype1.productPageDeatils;
  } else if (data === "contactUsPage") {
    headingData = headingtype1.contactUsPage;
  } else if (data === "ModularPMComplex") {
    headingData = headingtype1.ModularPMComplex;
  } else if (data === "ModularIcu") {
    headingData = headingtype1.ModularIcu;
  } else if (data === "modularLabourRoom") {
    headingData = headingtype1.modularLabourRoom;
  } else if (data === "modularOperationTheater") {
    headingData = headingtype1.modularOperationTheater;
  } else if (data === "psa") {
    headingData = headingtype1.psa;
  } else if (data === "BoosterPlant") {
    headingData = headingtype1.BoosterPlant;
  } else if (data === "liquidOxygenPlant") {
    headingData = headingtype1.liquidOxygenPlant;
  } else if (data === "MGPS") {
    headingData = headingtype1.MGPS;
  } else if (data === "laundryMachine") {
    headingData = headingtype1.laundryMachine;
  } else if (data === "waterTreatment") {
    headingData = headingtype1.waterTreatment;
  } else if (data === "iopMeasurment") {
    headingData = headingtype1.iopMeasurment;
  } else if (data === "videoLaryngoscope") {
    headingData = headingtype1.videoLaryngoscope;
  } else if (data === "viledCleaningSolution") {
    headingData = headingtype1.viledCleaningSolution;
  } else if (data === "ozoneFumigationSystem") {
    headingData = headingtype1.ozoneFumigationSystem;
  } else if (data === "oTLights") {
    headingData = headingtype1.oTLights;
  } else if (data === "oTTables") {
    headingData = headingtype1.oTTables;
  } else if (data === "suctionMachine") {
    headingData = headingtype1.suctionMachine;
  } else if (data === "autoClave") {
    headingData = headingtype1.autoClave;
  } else if (data === "CSSD") {
    headingData = headingtype1.CSSD;
  }
  return (
    <div class="row">
      <div class="row justify-content-md-center h1">
        {headingData?.title}
      </div>
      <div className="row justify-content-md-center subtitle">
        <br />
        {headingData?.subtitle}
        <br />
        <div className="bottom-shortline-center">
          <hr class="bottom-shortline" />
        </div>
      </div>
      <br />
      <div className="row content-style">{headingData?.content}</div>
      <br />
    </div>
  );
}
