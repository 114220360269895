import React from "react";
import { content } from "../data/ourProducts";
import HeadingType1 from "./HeadingType1";

export default function AboutUs() {
  const data = content.aboutUS;
  return (
    <div>
      <div class="container p-5">
        <HeadingType1 data="aboutUsHomePage" />
        <div class="row">
          <div class="col-md-12 content-style">{data.moreDescription}</div>
        </div>
      </div>
    </div>
  );
}
