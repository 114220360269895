import React from "react";
import CarouselImage from "./Carouselimage";

const ExampleCarouselImage = ({ text }) => {
  if (text === "firstImage") {
    return (
      <CarouselImage
        data={require("../asset/Home/Carousel/liquidOxygen.jpeg")}
        text="firstImage"
      />
    );
  } else if (text === "secondImage") {
    return (
      <CarouselImage
        data={require("../asset/Home/Carousel/mortuaryComplex.jpg")}
        text="secondImage"
      />
    );
  } else {
    return (
      <CarouselImage
        data={require("../asset/SubProductImages/PostMortemEquipments/Picture12.jpg")}
        text="thirdImage"
      />
    );
  }
};

export default ExampleCarouselImage;
