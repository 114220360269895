export const aboutPageData = {
  cards: [
    {
      key: 1,
      title: "What we do",
      content: `
Laxmi Surgicals and Pharma boasts an extensive product portfolio, ranging from hospital furniture to modular operation theaters, PSA oxygen generation technology to ozone fumigation systems, and an array of other essential items. Our offerings cater not only to hospitals, clinics, and doctors but also extend to patients in their homes.

With our wide-ranging products, we are proud to serve as a comprehensive solution provider for the medical industry. Our commitment to excellence and innovation is evident in the diverse array of offerings that address the varied needs of healthcare professionals and facilities.

Moreover, our global reach is facilitated through partnerships with distributors and wholesalers worldwide. Through these collaborations, our products are made available in local markets, ensuring accessibility and quality across geographical boundaries.

By delivering essential equipment and solutions to medical facilities and professionals globally, we remain dedicated to our mission of enhancing healthcare delivery and improving patient outcomes worldwide.

      `,
    },
    {
      key: 4,
      title: "Company's Philosophy",
      content: `
        Being entrusted with the responsibility of safeguarding the health and well-being of individuals, we hold ourselves to the highest standards, leaving no room for compromise. We deeply value the sanctity of life and recognize its inherent preciousness. From our inception, our business philosophy has been anchored in the principles of exceptional service, uncompromising quality, and enduring value.

At the core of our ethos lies a commitment to surpassing the expectations of not only our customers but also our employees and partners. We take pride in our ability to consistently exceed these expectations, setting new benchmarks for excellence within our industry.

Central to our approach is the cultivation of long-term relationships and mutual growth. We believe in fostering partnerships built on trust, integrity, and shared objectives, with the aim of achieving sustained prosperity for all involved.

In every aspect of our operations, we remain steadfast in our dedication to upholding these principles, ensuring that every interaction with our company reflects our unwavering commitment to service, quality, and mutual success.
        `,
    },
  ],
  upperManagementCards: [
    {
      key: 1,
      img: require("../asset/founderImg2.jpg"),
      title: "KUSHUMATI NAVINCHANDRA KHONA",
      content: "Founder",
    },
  ],
  managementCards: [
    {
      key: 1,
      img: require("../asset/CEO.jpg"),
      title: "SUNIL NAVINCHANDRA KHONA",
      content: "CEO",
    },
    {
      key: 2,
      img: require("../asset/cao.jpg"),
      title: "MEGHNA SUNIL KHONA",
      content: "CAO",
    },
  ],
};
