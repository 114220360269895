export const content = {
  aboutUS: {
    title: "Our Product",
    description:
      "Et cupidatat culpa est cillum elit officia duis. Aliqua aliqua qui dolore id irure voluptate ipsum nostrud enim aliqua. Adipisicing adipisicing eu ea nulla cupidatat. Sunt eiusmod proident officia quis laboris culpa anim velit sint cillum excepteur pariatur.",
    videoLink: "./dfa",
    moreDescription: `Laxmi Surgicals and Pharma is a leading private enterprise for over 18 years
providing Lamax brand Surgicals Instruments, Medical equipments, and services
needed in hospitals, especially in operation theatres. Our firm Laxmi Surgicals & Pharma
is based at Jalgaon [Maharashtra], We have presence in entire state of Maharashtra.
Each district of Maharashtra is covered by us. The firm was established in year 2003. We
have built and sustained an enviable reputation for the manufacture and supply of
Lamax surgical instruments and Equipment covering every type of surgical specialty.`,
  },
  clientReview: {
    title: "What our clients are saying",
    description: "The organic reviews not hoax.",
    reviews: [
      { description: "kuch kuch", clientName: "swapnil" },
      { description: "sab kuch", clientName: "abhijeet" },
    ],
  },
};
