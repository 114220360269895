export const heroWallpaper = {
  qualityHeroWallpaper: {
    title: "Certificates",
    imageURL: require("../asset/HeroWallpaper/quality.jpg"),
    gifURL:
      "https://lottie.host/embed/6256fb6f-0856-419a-aab4-35fc7daa66e6/tgR8guuhWw.json",
  },
  pricingPageHeroWallpaper: {
    title: "Gallery ",
    imageURL: require("../asset/HeroWallpaper/pricing.jpg"),
    gifURL:
      "https://lottie.host/embed/426d33f4-93cd-4939-8a85-46011dd240b8/ERW84igZFb.json",
  },
  productsPageHeroWallpaper: {
    title: "Products",
    imageURL: require("../asset/HeroWallpaper/product.jpg"),
    gifURL:
      "https://lottie.host/embed/6256fb6f-0856-419a-aab4-35fc7daa66e6/tgR8guuhWw.json",
  },
  contactInfoPageHeroWallpaper: {
    title: "Contact Information",
    imageURL: require("../asset/map.jpeg"),
    gifURL:
      "https://lottie.host/embed/6256fb6f-0856-419a-aab4-35fc7daa66e6/tgR8guuhWw.json",
  },
};
