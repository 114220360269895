import React from "react";
import { heroWallpaper } from "../data/heroWallpaper";
import GalleryImageGrid from "./Gallery/GalleryImageGrid";
import HeadingType1 from "./HeadingType1";
import HeroWallpaper from "./HeroWallpaper";
import "../components/css/PricingPage.css";

export default function Gallery() {
  const heroWallpaperData = heroWallpaper;
  return (
    <div>
      <HeroWallpaper data={heroWallpaperData.pricingPageHeroWallpaper} />
      <div class="container p-5">
        <HeadingType1 data="pricingPageDetails" />
        <div class="row">
          <div className="make-center bold-title pb-5">Recent Events</div>
          <div className=" make-center pt-5">
            <RotatingGallery />
          </div>
        </div>
        <div class="row make-center mt-5 pt-5">
          <div className="make-center bold-title pb-5">Images</div>
          <GalleryImageGrid />
        </div>
      </div>
    </div>
  );
}

function RotatingGallery() {
  const tutorialSteps = [
    {
      imgPath: require("../asset/Gallery/SpecialEvents/se1.jpg"),
      id: "1",
    },
    {
      imgPath: require("../asset/Gallery/SpecialEvents/se2.jpg"),
      id: "2",
    },
    {
      imgPath: require("../asset/Gallery/SpecialEvents/se3.jpg"),
      id: "3",
    },
    {
      imgPath: require("../asset/Gallery/SpecialEvents/se4.jpg"),
      id: "4",
    },
    {
      imgPath: require("../asset/Gallery/SpecialEvents/se5.jpg"),
      id: "5",
    },
    {
      id: "6",
      imgPath: require("../asset/Gallery/SpecialEvents/se6.jpg"),
    },
    {
      imgPath: require("../asset/Gallery/SpecialEvents/se5.jpg"),
      id: "7",
    },
    {
      id: "8",
      imgPath: require("../asset/Gallery/SpecialEvents/se6.jpg"),
    },
  ];
  return (
    <div>
      <div class="gallery">
        {tutorialSteps.map((imgItem) => (
          <span style={{ "--i": imgItem.id }}>
            <img src={imgItem.imgPath} alt="" />
          </span>
        ))}
      </div>
    </div>
  );
}
