import React from "react";
import { motion } from "framer-motion";

export default function ImageTitleCard({ data }) {
  const openPdf = () => {
    window.open(data?.pdf, "_blank");
  };
  return (
    <div class="col-md-3 col-sm-4 p-2">
      <motion.div whileHover={{ scale: 0.8 }} whileTap={{ scale: 0.9 }}>
        <div
          class="card border-0 card-img-bottom p-2"
          style={{
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            height: "15rem",
          }}
          onClick={openPdf}
        >
          <div className="make-center">
            <img src={data.imageURL} alt="img" height="150px" width="150px" />
          </div>
          <div class="card-body make-center">
            <h5
              class="card-title"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              {data.title}
            </h5>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
