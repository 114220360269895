import { makeStyles } from "@material-ui/styles";
import React from "react";
import Carousel from "react-material-ui-carousel";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

const tutorialSteps = [
  {
    imgPath: require("../../asset/Gallery/SpecialEvents/se1.jpg"),
    id: "512",
  },
  {
    imgPath: require("../../asset/Gallery/SpecialEvents/se3.jpg"),
    id: "12",
  },
  {
    imgPath: require("../../asset/Gallery/SpecialEvents/se2.jpg"),
    id: "312",
  },
  {
    imgPath: require("../../asset/Gallery/SpecialEvents/se4.jpg"),
    id: "412",
  },
  {
    imgPath: require("../../asset/Gallery/SpecialEvents/se5.jpg"),
    id: "132",
  },
  {
    id: "124",
    imgPath: require("../../asset/Gallery/SpecialEvents/se6.jpg"),
  },
];


const aboutImages = [ {
    imgPath: require("../../asset/Gallery/SpecialEvents/se1.jpg"),
    id: "512",
  },
  {
    imgPath: require("../../asset/Gallery/SpecialEvents/se3.jpg"),
    id: "12",
  },]

const useStyles = makeStyles({
  root: {
    margin: "10px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  header: {
    flexGrow: 1,
  },
  media: {
    height: "100%",
  },
  paper: {
    width: 300,
  },
  image: {
    width: "100%",
  },
  typo: {
    textAlign: "center",
  },
  mx: {
    margin: "6px 0px",
  },
  card: {
    width: 1000,
    height: 500,
  },
});

function CardSwipeable(props) {
  const classes = useStyles();
  return (
    <div className="container make-center">
      <div className="row">
        <div className="col">
          <div classes={classes.root}>
            <Card className={`${classes.root} ${classes.card}`}>
              <CardMedia className={classes.media} image={props.item.imgPath} />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function GalleryCarousel({page}) {
  const classes = useStyles();
  let data;
  if(page === "about"){
    data = aboutImages;
  }
  else {
    data = tutorialSteps;
  }
  return (
    <Carousel classeName={classes.root}>
      {data.map((item, i) => (
        <CardSwipeable key={i} item={item} />
      ))}
    </Carousel>
  );
}
